import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, interval, Observable, of, Subscription, throwError } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IApiResponse } from '../../shared/types/api.types';
import { IUser, UserService } from "./user.service";
import { IOrganization, OrganizationService } from './organization.service';
import { SelectedOrganizationService } from './selected-organization.service';

export interface ILoginRequest {
  username: string;
  password: string;
  two_factor_code: string | null;
}

export interface ILoginResponse {
  user: IUser;
  org: IOrganization;
  auth_token: string;
}

interface ITokenRefreshResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private _loggedInUser = new BehaviorSubject<IUser | null>(null);
  private _loggedInOrg = new BehaviorSubject<IOrganization | null>(null);
  private tokenCheck: Subscription | null = null;

  redirectUrl = '';

  constructor(
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private selectedOrgService: SelectedOrganizationService) {
    this.isAuthenticated();
  }

  get isLoggedIn(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  get loggedInUser(): Observable<IUser | null> {
    return this._loggedInUser.asObservable();
  }

  get loggedInOrg(): Observable<IOrganization | null> {
    return this._loggedInOrg.asObservable();
  }

  userLogin(loginRequest: ILoginRequest): Observable<IApiResponse<ILoginResponse>> {
    const url = this.configurationService.getUrl('/api/v1/login');
    const body = {
      'username': loginRequest.username,
      'password': loginRequest.password,
      'two_factor_code': loginRequest.two_factor_code
    };
    this.startTokenExpirationChecker();
    return this.httpClient.put<IApiResponse<ILoginResponse>>(url, body)
      .pipe(
        tap(response => {
          this.setLoggedInUser(<ILoginResponse>response.data);
          this.selectedOrgService.reset();
          this.configurationService.reset();
        }),
      );
  }

  refreshToken(): Observable<IApiResponse<ITokenRefreshResponse>> {
    const url = this.configurationService.getUrl('/api/v1/refresh-token');
    return this.httpClient.get<IApiResponse<ITokenRefreshResponse>>(url);
  }

  startTokenExpirationChecker() {
    if (this.tokenCheck || (location.pathname === '/help')) {
      return;
    }
    this.tokenCheck = interval(15000).pipe(
      switchMap(() => {
        const authToken = localStorage.getItem('auth_token');
        const tokenExpiring: boolean = this.getSecondsToExpiration(authToken) <= 600;
        const secondsSinceActivity = this.getSecondsSinceActivity();
        if (!secondsSinceActivity || !authToken) {
          return of(null);
        }
        const isActive: boolean = secondsSinceActivity <= 600;
        if (tokenExpiring && isActive) {
          return this.refreshToken().pipe(
            map(response => {
              return response.data.token;
            })
          );
        } else if (!this.jwtHelper.isTokenExpired(authToken)) {
          return of(authToken);
        } else {
          return of(null);
        }
      }),
      tap(newAuthToken => {
        if(newAuthToken) {
          localStorage.setItem('auth_token', newAuthToken);
        }
      }),
    ).subscribe(newAuthToken => {
      if (!newAuthToken || this.jwtHelper.isTokenExpired(newAuthToken)) {
        this.logout(true);
      }
    });
  }

  stopTokenExpirationChecker() {
    if (this.tokenCheck) {
      this.tokenCheck.unsubscribe();
      this.tokenCheck = null;
    }
  }

  public logout(expiring: boolean = false) {
    this.clearLoginState();
    this.stopTokenExpirationChecker();
    if (expiring) {
      this.router.navigate(['login'], {
        queryParams: {
          expiring: true
        }
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  public getBearerToken(): string {
    const auth_token = localStorage.getItem('auth_token');
    if (!auth_token) {
      this.clearLoginState();
    }
    return 'Bearer ' + auth_token;
  }

  public hasToken(): boolean {
    const hasToken = !!localStorage.getItem('auth_token');
    if (!hasToken) {
      this.clearLoginState();
    }
    return hasToken;
  }

  getSecondsToExpiration(authToken: string | null): number {
    if (authToken) {
      const expires = this.jwtHelper.getTokenExpirationDate(authToken);
      const now = new Date();
      if (expires) {
        return (expires.getTime() - now.getTime()) / 1000;
      }
    }
    return 0;
  }

  getSecondsSinceActivity(): number | null {
    const lastActivityString = localStorage.getItem('_activity');
    if (lastActivityString) {
      const lastActivity = parseInt(lastActivityString);
      const now = (new Date()).getTime();
      const seconds = (now - lastActivity) / 1000;
      return seconds;
    }
    return null;
  }

  public isAuthenticated(): boolean {
    try {
      const auth_token = localStorage.getItem('auth_token');
      const loggedInUser = localStorage.getItem('loggedInUser');
      const loggedInOrg = localStorage.getItem('loggedInOrg');

      if (auth_token) {
        this.startTokenExpirationChecker();
      }

      // Check if we have a non-expired auth token and a loggedInUser
      // in localStorage to see if we are authenticated, best we can do locally.
      if (auth_token && loggedInUser && loggedInOrg) {
        const expired = this.jwtHelper.isTokenExpired(auth_token);
        if (expired) {
          this.clearLoginState();
          return false;
        } else {
          const user = JSON.parse(loggedInUser);
          const org = JSON.parse(loggedInOrg);

          let userInfo: ILoginResponse = {
            user: user,
            org: org,
            auth_token: auth_token
          }
          this.setLoggedInUser(userInfo);
          return true;
        }
      }
      this.clearLoginState();
      return false;

    } catch (error) {
      this.clearLoginState();
      return false;
    }
  }

  private clearLoginState() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('loggedInUser');
    this?.isLoggedInSubject.next(false);
    this?._loggedInUser.next(null);
  }

  private setLoggedInUser(response: ILoginResponse) {
    if (response) {
      const loggedInUser = response.user;

      localStorage.setItem('auth_token', response.auth_token);
      localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
      localStorage.setItem('loggedInOrg', JSON.stringify(response.org));

      this._loggedInUser.next(loggedInUser);
      this._loggedInOrg.next(response.org)
      this.isLoggedInSubject.next(true);


    } else {
      this.clearLoginState();
    }
  }

  public setLoggedInUserFromIUser(user: IUser) {
    const loggedInUser = {
      id: user.id,
      username: user.username,
      email: user.email ? user.email : '',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      org_id: user.org_id ?? 0,
      twoFactorEnabled: user.twoFactorEnabled ?? 0,
      readonly: user.readonly ?? 1
    };

    localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
    this._loggedInUser.next(loggedInUser);
    this.isLoggedInSubject.next(true);
  }
}
