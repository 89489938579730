import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable, of } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { Title } from '@angular/platform-browser';
import { ITheme } from './shared/types/theme.types';
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import {ConfigurationService} from "./core/services/configuration.service";

@Component({
  selector: 'bsc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  help: boolean;
  isLoggedIn$: Observable<boolean> = of(false);
  title = 'Console';

  themes: ITheme[] = [
    {value: "dark-0", displayValue: "Dark"},
    {value: "light-1", displayValue: "Light"}
  ]
  selectedTheme = this.themes[0].value;

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
  ) {
    // this.renderer.addClass(document.body, 'indigo-pink');
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.setTitle(configurationService.title);
    this.help = location.pathname === '/help';
  }

  ngOnInit() {
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  onThemeChanged(event: MatSelectChange): void {
    this.selectedTheme = event.value;
    if (this.selectedTheme === 'light-1') {
      this.renderer.addClass(document.body, 'bsc-light-theme');
    } else {
      this.renderer.removeClass(document.body, 'bsc-light-theme');
    }
  }

  @HostListener('window:click')
  @HostListener('window:keydown')
  @HostListener('window:scroll')
  onActivityEvent() {
    const now = new Date();
    const time = now.getTime();
    localStorage.setItem("_activity", time.toString());
  }
}
