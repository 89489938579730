import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopToolbarService {
  public breadcrumb = new BehaviorSubject('');

  constructor() {
  }

  setBreadcrumb(name: string) {
    this.breadcrumb.next(name);
  }
}
