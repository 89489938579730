import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ErrorCode } from '../shared/types/api.types';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Theme, ThemeService } from '../core/services/theme.service';
import {ConfigurationService} from "../core/services/configuration.service";

@Component({
  selector: 'bsc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  destroy$: Subject<null> = new Subject<null>();
  loginForm: FormGroup;
  username = '';
  show2fa = false;
  loginError = false;
  timedOut = false;
  theme: Observable<Theme>;
  translationPostfix: string;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private route: ActivatedRoute,
              private themeService: ThemeService,
              private configurationService: ConfigurationService) {
    this.translationPostfix = configurationService.translationPostfix;
    this.theme = this.themeService.getTheme();
    this.loginForm = this.buildForm();
    route.queryParamMap.pipe(
      takeUntil(this.destroy$)
    ).subscribe((params: ParamMap) => {
      if (params.get('expiring') === 'true') {
        this.timedOut = true;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  buildForm(): FormGroup {
    return this.formBuilder.group({
      username: [this.username ? this.username : '', Validators.required],
      password: ['', Validators.required],
      twoFactor: null
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const loginRequest = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
        two_factor_code: this.loginForm.value.twoFactor
      };

      this.authService.userLogin(loginRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
            this.loginError = false;
            if (this.authService.redirectUrl) {
              this.router.navigateByUrl((this.authService.redirectUrl));
            } else {
              this.router.navigate(['dashboard']);
            }
          },
          error => {
            if (error.status === 403 && error.error?.error_code === ErrorCode.TWO_FACTOR_CODE_REQUIRED) {
              this.loginError = false;
              this.show2fa = true;
            } else if (error.error?.error_code === ErrorCode.LOGIN_VERIFY_FAIL) {
              this.clearForm(this.loginForm);
              this.loginForm?.get('twoFactor')?.setValue(null);
              this.loginForm?.get('twoFactor')?.setErrors(null);
              this.loginError = true;
              this.show2fa = false;
            }
            else {
              this.clearForm(this.loginForm);
            }
          }
        )
    }

  }

  clearForm(form: FormGroup) {
    form?.get('username')?.setValue('');
    form?.get('password')?.setValue('');
    form?.get('twoFactor')?.setValue('');
  }

}
