import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import {ConfigurationService} from "../core/services/configuration.service";
import {ThemeService} from "../core/services/theme.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'bsc-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements AfterViewInit {
  themeClass$ = this.themeService.getTheme().pipe(
    map(theme => {
      switch (theme) {
        case 'light':
          return 'light-theme-logo';
        case 'dark':
          return 'light-theme-logo';  // no dark theme on this page, just use light
        case 'eska-light':
          return 'eska-light-theme-logo';
      }
    })
  );

  constructor(private readonly route: ActivatedRoute,
              private readonly scroller: ViewportScroller,
              public readonly configurationService: ConfigurationService,
              private readonly themeService: ThemeService) { }

  ngAfterViewInit(): void {
    this.scroller.scrollToAnchor(this.route.snapshot.fragment || '');
  }
}
