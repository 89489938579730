import {Inject, Injectable} from '@angular/core';
import {WINDOW} from "../providers/window.providers";

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(@Inject(WINDOW) private window: any) { }

  getHostName(): string {
    return this.window.location.hostname;
  }

}
