import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOrganization } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedOrganizationService {
  public selectedOrganizationId = new BehaviorSubject<string>('0');
  public selectedOrganization = new BehaviorSubject<IOrganization>({} as IOrganization);
  public treeNodeSelected = new BehaviorSubject<string>('0');

  constructor() {
  }

  reset(): void {
    this.selectedOrganizationId.next('0');
    this.selectedOrganization.next({} as IOrganization);
  }

  setSelectedOrganizationId(organizationId: string): void {
    this.selectedOrganizationId.next(organizationId);
  }

  getSelectedOrganizationId(): string {
    return this.selectedOrganizationId.value;
  }

  setSelectedOrganization(organization: IOrganization): void {
    this.selectedOrganization.next(organization);
  }

  getSelectedOrganization(): IOrganization {
    return this.selectedOrganization.value;
  }
}
