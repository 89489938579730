<h2 class="alert-title" mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content tabindex="-1" class="mat-typography">
    <p>{{data.content}}</p>
    <div *ngFor="let addContent of data.additionalContent">
    <p>{{addContent}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="data.showRejectBtn" mat-stroked-button [mat-dialog-close]="false">{{data.rejectBtnName}}</button>
    <button mat-stroked-button [mat-dialog-close]="true">{{data.acceptBtnName}}</button>
</mat-dialog-actions>
