<mat-dialog-content>
<p>{{'password-rules.title' | translate}}</p>
<ul>
    <li>
        <strong>{{ MIN_PASSWORD_LENGTH }}</strong> {{'password-rules.to' | translate}} <strong>{{ MAX_PASSWORD_LENGTH }}</strong> {{'password-rules.characters' | translate}}
    </li>
</ul>
<ul>
    <li>
        {{'password-rules.subtitle' | translate}}
    </li>
    <p>

    </p>
    <li>
        <strong>{{'password-rules.uppercase' | translate}}</strong> {{'password-rules.letter' | translate}}
    </li>
    <li>
        <strong>{{'password-rules.lowercase' | translate}}</strong> {{'password-rules.letter' | translate}}
    </li>
    <li>
        <strong>{{'password-rules.unicode' | translate}} </strong> {{'password-rules.asian-characters' | translate}}
    </li>
    <li>
        <strong>{{'password-rules.number' | translate}}</strong>
    </li>
    <li>
        <strong>{{'password-rules.special-character' | translate}}</strong>
    </li>
</ul>
</mat-dialog-content>
<div mat-dialog-actions class="button-wrapper">
    <button mat-raised-button (click)="onClose()" type="button" color="primary">{{'common.close' | translate}}</button>
</div>
