import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
    private jwtHelper: JwtHelperService,) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Don't send Auth header for rest APi calls when we don't have a JWT token.
    if (this.authService.hasToken()) {
      const token = this.jwtHelper.tokenGetter();
      let expired;
      try {
        expired = this.jwtHelper.isTokenExpired(token);
      } catch (e) {
        expired = true;
      }

      if (this.router.url.startsWith('/login') && expired) {
        localStorage.removeItem('auth_token');
      } else {
        request = request.clone({
          setHeaders: {
            'Authorization': this.authService.getBearerToken()
          },
        });
      }
    }

    request = request.clone({
      setHeaders: {
        'Accept-Language': `${localStorage.getItem('language')}` || 'en'
      },
    });

    return next.handle(request);
  }

}
