<div *ngIf="enabled" [ngClass]="{'expansion-panel': show, 'small-panel': !show}">
  <div>
    <div class="nav-tree-title clickable" (click)="onTreeSelect()"><span>{{ 'navtree.title' | translate }}</span></div>
  </div>

  <div *ngIf="show" class="tree-content">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      // This node is the DEFAULT node, the node below has a when clause (hasChild).  It will be used instead
      // of this default node.
      // You can have another definition of a node with a different when: clause and that would get used instead, this
      // allows for any number of different types of nodes in the tree, perhaps you want to have different icons or opening
      // images or some other different behavior.
      <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding matTreeNodePaddingIndent="{{treePadding}}">
        <button mat-icon-button disabled></button>
        <div id="node-{{node.itemId}}" class="clickable nav-tree-item"
             [ngClass]="{'selected-tree-item': this.dataSource.selectedNode && node.itemId == this.dataSource.selectedNode.itemId,
                         'not-selected-tree-item': !(this.dataSource.selectedNode && node.itemId == this.dataSource.selectedNode.itemId)}"
             (click)="onSelect($event, node)"><span>{{node.item}}</span></div>
      </mat-tree-node>
      // This node replaces the default node WHEN hasChild is true.
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="{{treePadding}}">
        <button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle class="clickable">
          <i class="mdi {{treeControl.isExpanded(node) ? 'mdi-chevron-down' : 'mdi-chevron-right'}}">
          </i>
        </button>
        <div id="node-{{node.itemId}}" class="clickable nav-tree-item"
             [ngClass]="{'selected-tree-item': this.dataSource.selectedNode && node.itemId == this.dataSource.selectedNode.itemId,
                         'not-selected-tree-item': !(this.dataSource.selectedNode && node.itemId == this.dataSource.selectedNode.itemId)}"
             (click)="onSelect($event, node)">
          <span>{{node.item}}</span>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
