export interface TreeItem {
    itemId: number;
    itemName: string;
    canHaveChildren: boolean
    parents?: TreeItem[];
}

export interface DataProvider {
  getChildren: (itemId: number) => Promise<TreeItem[]>;

  // search(searchValue: string) => Promise<TreeItem[]>;
  getPath(itemId: number): Promise<TreeItem[]>;

}

export class DynamicFlatNode {
  constructor(
    public item: string,
    public itemId: number,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public isSelected = false
  ) {
  }
}
