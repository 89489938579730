import { IOrganization, OrganizationService, OrgType } from '../services/organization.service';
import { IApiResponse } from '../../shared/types/api.types';
import { SidenavComponent } from './sidenav.component';
import { DataProvider, TreeItem } from '../../shared/components/navigation-tree/tree-types';

export class TreeDataProvider implements DataProvider {
    count = 0;

    constructor(private orgService: OrganizationService, private sidenavComponent: SidenavComponent) {
    }

    getChildren(itemId: number): Promise<TreeItem[]> {
        return new Promise((resolve, reject) => {
            if (itemId == -1) {
                const orgs: TreeItem[] = [];
                if (this.sidenavComponent.loggedInOrg) {
                    const treeItem = this.makeTreeItem(this.sidenavComponent.loggedInOrg, true);
                    orgs.push(treeItem);
                    resolve(orgs)
                } else {
                    // TODO: what do i do here?
                    console.warn("did not get root node in time")
                    resolve(orgs);
                }
            } else {
                this.orgService.readChildren(itemId).subscribe(response => {
                    const children = this.convertToTreeItem(response);
                    resolve(children);
                });
            }
        });
    }

    private convertToTreeItem(response: IApiResponse<IOrganization[]>): TreeItem[] {
        const orgs: TreeItem[] = [];
        for (let i = 0; i < response.data.length; i++) {
            const org: IOrganization = response.data[i];
            let canHaveChildren = true;
            if (org.type == OrgType.CUSTOMER) {
                canHaveChildren = false;
            }
            if (org.id !== undefined) {
                let newItem = this.makeTreeItem(org, canHaveChildren);
                orgs.push(newItem);
            }
        }
        return orgs;
    }

    private makeTreeItem(org: IOrganization, canHaveChildren: boolean) {
        let id = -2;
        if (org.id) {
            id = org.id;
        }
        let newItem: TreeItem = {
            'itemName': org.name,
            'itemId': id,
            'canHaveChildren': canHaveChildren
        }
        return newItem;
    }

    getPath(itemId: number): Promise<TreeItem[]> {
        return new Promise((resolve, reject) => {
            this.orgService.getOrgAndParents(itemId).subscribe( response => {
              const path = this.convertToTreeItem(response);
              resolve(path)
            },
              error => {
                resolve([]);
              });
        });
    }
}
