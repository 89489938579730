import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { IOrganization, OrgType } from '../../services/organization.service';
import { SelectedOrganizationService } from '../../services/selected-organization.service';


@Component({
  selector: 'bsc-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss']
})
export class OrganizationSelectorComponent implements OnInit, DoCheck {
  @Input() organizations: IOrganization[] = [];
  @Output() selectedOrgChanged: EventEmitter<string> = new EventEmitter<string>();
  selectedOrganizationId = '0';

  showAll = true;
  constructor(private selectedOrgService: SelectedOrganizationService) {
    selectedOrgService.treeNodeSelected.subscribe( orgId => {
      // console.log("OrgSelected caught tree node selection " + orgId);
      if ( this.selectedOrganizationId != orgId ) {
        this.selectedOrganizationId = orgId
        this.selectedOrgChanged.emit(this.selectedOrganizationId);
      }
    })
  }

  ngOnInit(): void {
    this.showAll = true;
    this.selectedOrganizationId = '0';
  }

  ngDoCheck(): void {
    if ( this.organizations.length == 1 && this.organizations[0].type == OrgType.CUSTOMER) {
      this.checkForChanges(false);
    }
  }

  private checkForChanges(showall: boolean) {
      // @ts-ignore
    if (this.selectedOrganizationId != this.organizations[0].id.toString()) {
        // @ts-ignore
        this.selectedOrganizationId = this.organizations[0].id.toString();
        this.showAll = showall;
        this.selectedOrgChanged.emit(this.selectedOrganizationId);
      }
  }

  onOrgSelectChanged(event: MatSelectChange) {
    this.selectedOrganizationId = event.value.toString();
    this.selectedOrgChanged.emit(this.selectedOrganizationId);
  }


}
