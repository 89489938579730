import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DynamicDataSource } from './dynamic-data.source';
import { DataProvider, DynamicFlatNode, TreeItem } from './tree-types';
import { DynamicDatabase } from './dynamic-database.service';


@Component({
  selector: 'bsc-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss']
})
export class NavigationTreeComponent implements OnInit, OnChanges {
  enabled = true;
  show = true;

  @Input() dataProvider?: DataProvider = undefined;

  // This is how the external app can tell the tree to select an item
  @Input() selectItem: TreeItem | undefined;

  // This is how the caller causes the tree to refresh when node info has changed
  @Input() treeRefresh: number | undefined;

  // output the selected item
  @Output() nodeItemIdSelected = new EventEmitter<number>();

  searchString: String | null = null;

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;
  database: DynamicDatabase;
  private select: TreeItem | undefined;
  treePadding = 10;

  constructor() {
    this.database = new DynamicDatabase();

    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this, this.treeControl, this.database);
  }

  ngOnInit(): void {
    if (this.dataProvider) {
      this.database.setProvider(this.dataProvider);
      this.dataSource.initialize();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.selectItem && (changes.selectItem.currentValue != changes.selectItem.previousValue) ) {
      // console.log("selected Item has changed");
      this.select = changes.selectItem.currentValue;
      if ( this.select && this.select.itemId ) {
        if ( this.select.itemId > 0 ) {
          this.nodeItemIdSelected.emit(this.select.itemId);
        }
        this.dataSource.selectNode(this.select.itemId, true)
      }
    }
    if ( changes.treeRefresh && (changes.treeRefresh.currentValue != changes.treeRefresh.previousValue) ) {
      this.dataSource.refreshAll();
    }

  }

  onSelect($event: Event, node: any) {
    // console.log("node selected!")
    let itemId = node.itemId;
    if ( this.dataSource.selectedNode && this.dataSource.selectedNode.itemId == node.itemId ) {
      //clicking on same node
      itemId = 0;
    }
    this.nodeItemIdSelected.emit(itemId);
    // we are clicking it, we don't need to scroll to it
    this.dataSource.selectNode(itemId, false)

  }

  onTreeSelect() {
    this.show = !this.show;
    this.dataSource.refresh();
  }
}
