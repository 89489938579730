import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { IApiResponse } from '../../shared/types/api.types';

// The ORG part of the url is stuck onto the url before calling in the OrganizationSelectorInterceptor
export abstract class RestService<T extends { id?: number }, E> {
  protected readonly APIUrl: string;

  protected constructor(protected httpClient: HttpClient,
                        protected configurationService: ConfigurationService,
                        protected endpoint: string) {
    this.APIUrl = this.configurationService.getUrl(endpoint);
  }

  public query(options: Object = {}): Observable<IApiResponse<T[]>> {
    const apiUrl = `${this.APIUrl}`;

    return this.httpClient.get<IApiResponse<T[]>>(apiUrl, options)
      .pipe(
        map((response: IApiResponse<T[]>) => response),
        catchError(this.handleError)
      );
  }

  public readAll(): Observable<IApiResponse<T[]>> {
    return this.query({});
  }



  public read(id: number, options: Object = {}): Observable<IApiResponse<T>> {
    const apiUrl = `${this.APIUrl}/${id}`;

    return this.httpClient.get<IApiResponse<T>>(apiUrl, options)
      .pipe(
        map((response: IApiResponse<T>) => response),
        catchError(this.handleError)
      );
  }

  public readResponse(id: number, options: Object = {}): Observable<HttpResponse<T>> {
    const apiUrl = `${this.APIUrl}/${id}`;
    options = {...options, observe: 'response'}

    return this.httpClient.get<HttpResponse<T>>(apiUrl, options)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  }

  public create(resource: T, options: Object = {}): Observable<IApiResponse<E>> {
    const apiUrl = `${this.APIUrl}`;

    return this.httpClient.post<IApiResponse<E>>(apiUrl, resource, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  public delete(id: number, options: Object = {}): Observable<IApiResponse<E>> {
    const apiUrl = `${this.APIUrl}/${id}`;

    return this.httpClient.delete<IApiResponse<E>>(apiUrl, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  public update(resource: T, options: Object = {}): Observable<IApiResponse<E>> {
    const apiUrl = `${this.APIUrl}/${resource.id}`;
    return this.httpClient.put<IApiResponse<E>>(apiUrl, resource, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  public handleError(error: any): Observable<any> {
    return throwError(error.message || error);
  }

}
