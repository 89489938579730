import { Pipe, PipeTransform } from '@angular/core';
import { ICurrentNavState } from './sidenav.component';

@Pipe({
  name: 'contentCss'
})
export class ContentCssPipe implements PipeTransform {

  transform(currentNavState: ICurrentNavState, closedMediaStates: string[]): string {
    let cssClasses: string;

    if (closedMediaStates.includes(currentNavState.activeMedia)) {
      cssClasses = 'no-margin-content';

    } else {
      if (currentNavState.isOpened) {
        if (currentNavState.isExpanded) {
          if (currentNavState.showTree) {
            cssClasses = 'extra-wide-content';
          } else {
            cssClasses = 'wide-content';
          }
        } else {
          cssClasses = 'narrow-content';
        }
      } else {
        if (currentNavState.isExpanded) {
          cssClasses = 'no-margin-content';
        } else {
          cssClasses = 'narrow-content';
        }

      }
    }

    return cssClasses;
  }

}
