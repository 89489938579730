<ng-container *ngIf="formGroup" [formGroup]="formGroup">
<mat-form-field [appearance]="appearance" [ngStyle]="styles">
    <mat-label>{{'COUNTRY' | translate}}</mat-label>
    <ng-container *ngIf="required; else notRequired">
        <mat-select formControlName="country" name='country' [compareWith]="compareCountries" (selectionChange)="countryChanged()" required disableOptionCentering>
          <mat-option *ngFor="let country of countries" [value]="country" >
            {{ country?.name }}
          </mat-option>
        </mat-select>
    </ng-container>
    <mat-error *ngIf="countryControl?.errors?.required">{{'COUNTRY_COMPONENT_REQUIRED' | translate}}</mat-error>
    <ng-template #notRequired>
        <mat-select formControlName="country" name='country' [compareWith]="compareCountries" (selectionChange)="countryChanged()" disableOptionCentering>
          <mat-option *ngFor="let country of countries" [value]="country" >
            {{ country?.name }}
          </mat-option>
        </mat-select>
    </ng-template>
</mat-form-field>
</ng-container>
