import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../../services/user.service';

export interface IProfileInfo {
  displayName: string,
  initials: string;
}

@Pipe({
  name: 'profileInfo'
})
export class ProfileInfoPipe implements PipeTransform {

  transform(user: IUser | null): IProfileInfo | null {
    let displayName = '';
    let initials = '';

    if (!user) {
      return null;
    }

    let {firstName, lastName, username} = user;

    firstName = firstName.trim();
    lastName = lastName.trim();

    if (firstName && lastName) {
      displayName = `${firstName} ${lastName}`;
      initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();
    } else if (firstName) {
      displayName = firstName;
      initials = firstName[0].toUpperCase();
    } else if (lastName) {
      displayName = lastName;
      initials = lastName[0].toUpperCase();
    } else {
      displayName = username;
      initials = username[0].toUpperCase();
    }

    return {displayName, initials};
  }

}
