import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService implements OnDestroy {
  destroy$: Subject<null> = new Subject<null>();
  private toggleSidenavSubject = new BehaviorSubject<void>(undefined);

  constructor() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  toggle(): void {
    this.toggleSidenavSubject.next();
  }

  getToggleSubject(): BehaviorSubject<void> {
    return this.toggleSidenavSubject;
  }

}
