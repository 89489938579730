<mat-toolbar>
  <mat-toolbar-row>
    <div class="bsc-logo-wrapper" [ngClass]="{'light-theme-logo': (theme | async) === 'light', 'dark-theme-logo': (theme | async) === 'dark', 'eska-light-theme-logo': (theme | async) === 'eska-light'}" >
      <button mat-icon-button color="primary" ngClass.lt-md="menu-button-small" class="menu-button" (click)="toggleSidenav()">
        <i class="mdi mdi-menu"></i>
      </button>
      <span class="bsc-logo" fxShow fxHide.lt-md></span>
      <span class="breadcrumb" fxHide fxShow.lt-md ngClass.lt-sm="breadcrumb-lt-sm">{{breadcrumb}}</span>
    </div>

    <span class="menu-spacer"></span>

    <!-- Other components can start after this comment -->

    <div class="org-selector-wrapper">
      <bsc-organization-selector [organizations]="organizations"
                                 (selectedOrgChanged)="onSelectedOrgChanged($event)"></bsc-organization-selector>
    </div>

    <!-- Other components should end before this comment -->
    <div fxShow fxHide.lt-lg class="profile-divider"></div>
    <div class="profile-wrapper">
      <bsc-profile-menu [user]="loggedInUser" (signOutClick)="onSignOut()"></bsc-profile-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

