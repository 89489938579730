<div class="login">
  <header class="logo header" [ngClass.lt-md]="{'header-sm': true, 'header': false}">
    <span class="bsc-logo" fxShow [ngClass]="{'light-theme-logo': (theme | async) === 'light', 'dark-theme-logo': (theme | async) === 'dark', 'eska-light-theme-logo': (theme | async) === 'eska-light'}"></span>
  </header>
  <div>
    <mat-card class="mat-elevation-z12 card" style="width: 30rem;" [ngStyle.lt-md]="{'width': '275px'}">
      <mat-card-content>
        <h1>{{'sign-in.title' | translate}}</h1>
        <div class="sign-in-desc">{{ ('sign-in.description-' + translationPostfix) | translate}}</div>
        <div>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form form-wrapper" [ngClass.lt-md]="{'form-wrapper-sm': true, 'form-wrapper': false}">
              <div class="login-error" *ngIf="loginError; else timedOutDiv">
                <span tabindex="1" class="error-message">{{'sign-in.error.invalid-login' | translate}}</span>
              </div>
              <ng-template #timedOutDiv>
                <div class="login-error" *ngIf="timedOut">
                  <span tabindex="1" class="error-message">{{'sign-in.error.login-timed-out' | translate}}</span>
                </div>
              </ng-template>
              <span *ngIf="!show2fa">
                <mat-form-field class="login-form-field">
                  <mat-label>{{'sign-in.username' | translate}}</mat-label>
                  <input id="username" matInput placeholder="{{'sign-in.username' | translate}}"
                         formControlName="username" [value]="username">
                </mat-form-field>

                <mat-form-field class="login-form-field">
                  <mat-label>{{'sign-in.password' | translate}}</mat-label>
                  <input id="password" matInput type="password" placeholder="{{'sign-in.password' | translate}}"
                         formControlName="password">
                </mat-form-field>
              </span>
              <span *ngIf="show2fa">
                <div style="padding-bottom: 10px;">
                  <mat-label>
                    <b>Two-Factor Authentication</b>&nbsp;is required to complete login. Please submit your 6-digit code below:
                  </mat-label>
                </div>
                <mat-form-field class="twofa-form-field" appearance="outline">
                  <input matInput type="twoFactor" placeholder="Enter your 6-digit 2FA*" aria-label="2FA"
                         autocomplete="off" formControlName="twoFactor" required/>
                </mat-form-field>
                <div class="recovery-code" style="padding-top: 10px;">
                  <div class="recovery-use-warning">
                    * You can also use your one-time recovery code, which if successful will turn off TFA.
                  </div>
                </div>
              </span>
              <div>
                <button color="primary" id="sign-in" class="sign-in-button"
                        mat-raised-button>{{'sign-in.submit-button' | translate}}</button>
              </div>
              <div class="reset-form-wrapper">
                  <div class="reset-password">
                    <a routerLink="/reset" routerLinkActive="active">{{'reset-password.forgot-password' | translate}}</a>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>
