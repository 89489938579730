import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type Theme = 'light' | 'dark' | 'eska-light';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  private _theme = new BehaviorSubject<Theme>('light');

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const theme = this.getLocalStorageTheme();
    this.setTheme(theme);
  }

  private getLocalStorageTheme(): Theme {
    const localStorageTheme = localStorage.getItem('theme') ?? 'light';
    return localStorageTheme as Theme;
  }

  public getTheme(): Observable<Theme> {
    return this._theme.asObservable();
  }

  public setTheme(theme: Theme) {
    localStorage.setItem('theme', theme);
    this._theme.next(theme);

    // TODO: Find a way to stop hard-coding this translation from theme to theme class

    if (theme === 'dark') {
      this.renderer.addClass(document.body, 'bsc-dark-theme');
    } else {
      this.renderer.removeClass(document.body, 'bsc-dark-theme');
    }

    if (theme === 'eska-light') {
      this.renderer.addClass(document.body, 'eska-light-theme');
    } else {
      this.renderer.removeClass(document.body, 'eska-light-theme');
    }

  }

}
