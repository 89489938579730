<mat-form-field appearance="outline" ngClass.lt-sm="org-form-field-xs">
  <mat-select id="organization-select-control" name="organization-select" aria-label="Select Organization"
              [(ngModel)]="selectedOrganizationId"
              (selectionChange)="onOrgSelectChanged($event)">
    <mat-option *ngIf="showAll" id="org-0" value="0">{{'top-toolbar.org-selector.all-orgs' | translate}}</mat-option>
    <mat-option *ngFor="let org of organizations" id="org-{{org.id}}" [value]="org.id?.toString()">
      {{ org.name | titlecase }}
    </mat-option>
  </mat-select>
</mat-form-field>
