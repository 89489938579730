import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { map, takeUntil } from 'rxjs/operators';
import { DynamicFlatNode, TreeItem } from './tree-types';
import { DynamicDatabase } from './dynamic-database.service';
import { NavigationTreeComponent } from './navigation-tree.component';

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
    dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
    private destroy$: Subject<null> = new Subject<null>();

    public selectedNode: DynamicFlatNode | undefined;
    private selectingNode: number | null = null;
    private selectPath: TreeItem[] | null = null;
    private scrollAttempts: number = 0

    get data(): DynamicFlatNode[] {
        return this.dataChange.value;
    }

    set data(value: DynamicFlatNode[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    constructor(
        private _navTree: NavigationTreeComponent,
        private _treeControl: FlatTreeControl<DynamicFlatNode>,
        private _database: DynamicDatabase,
    ) {
    }

    initialize() {
        this._database.getChildren(-1).then(
            (children) => {
                this._database.setInitialData(children);
                this.data = this._database.initialData();
            }
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
        this._treeControl.expansionModel.changed
          .pipe(takeUntil(this.destroy$))
          .subscribe(change => {
            if (
                (change as SelectionChange<DynamicFlatNode>).added ||
                (change as SelectionChange<DynamicFlatNode>).removed
            ) {
                this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.destroy$.next();
      this.destroy$.complete();
    }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
        if (change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed
                .slice()
                .reverse()
                .forEach(node => this.toggleNode(node, false));
        }
    }

    /**
     * Toggle the node, remove from display list
     */
    toggleNode(node: DynamicFlatNode, expand: boolean) {
        const index = this.data.indexOf(node);

        this._database.getChildren(node.itemId).then(
            (children) => {
                if (!children || index < 0) {
                    // If no children, or cannot find the node, no op
                    return;
                }

                node.isLoading = true;
                if (expand) {
                    // only add new nodes
                    // TODO: this is VERY inefficient!
                    const newNodes: TreeItem[]  = []
                    children.forEach( child => {
                      let found = false;
                      this._treeControl.dataNodes.forEach( dataNode => {
                        if ( child.itemId == dataNode.itemId ) {
                          found = true;
                        }
                      });
                      if ( !found ) {
                        newNodes.push(child);
                      }
                    })
                    // for (let i = 0; i < children.length; i++) {
                    //   let found = false;
                    //   const child = children[i]
                    //   for (let j = 0; j < this.data.length; j++) {
                    //     const dataNode = this.data[j];
                    //     if ( child.itemId == dataNode.itemId ) {
                    //       found = true;
                    //       break;
                    //     }
                    //   }
                    //   if ( !found ) {
                    //     newNodes.push(child);
                    //   }
                    // }
                    if ( newNodes.length > 0 ) {
                      const nodes = newNodes.map(
                        child => new DynamicFlatNode(child.itemName, child.itemId, node.level + 1, child.canHaveChildren),
                      );
                      this.data.splice(index + 1, 0, ...nodes);
                    }
                } else {
                    let count = 0;
                    for (
                        let i = index + 1;
                        i < this.data.length && this.data[i].level > node.level;
                        i++, count++
                    ) {
                    }
                    this.data.splice(index + 1, count);
                }

                // notify the change
                this.dataChange.next(this.data);
                node.isLoading = false;
                if ( expand ) {
                    this.verifySelectPath(node, children);
                }
            });
    }


  private verifySelectPath(node: DynamicFlatNode, children: TreeItem[]) {
    if (this.selectPath) {
      if (this.selectedNode && node.itemId == this.selectedNode?.itemId) {
        this.selectNode(node.itemId, true);
      }
      // look for a node in the list from the children list

      for (let i = 0; i < children.length; i++) {
        if ( !this.selectPath ) {
          break;
        }
        const child = children[i];
        const matches = this.selectPath.filter(treeItem => treeItem && child &&
                                                           treeItem.itemId && child.itemId &&
                                                           treeItem.itemId === child.itemId);
        if (matches && matches.length > 0) {
          const match = matches[0];
          if ( match.itemId == this.selectingNode ) {
            this.selectNode(match.itemId, true);
          } else {
            this.openNode(match);
          }
        }
      }
    }
  }

  openNode(treeItem: TreeItem) {
      for (let i = 0; i < this.data.length; i++) {
        const dataNode = this.data[i];
        if (dataNode.itemId == treeItem.itemId) {
          //this._treeControl.collapse(dataNode);
          if ( this._treeControl.isExpanded(dataNode) ) {
            this._database.getChildren(dataNode.itemId).then( children => {
              this.verifySelectPath(dataNode, children);
            })
          } else {
            this._treeControl.expand(dataNode);
            break;
          }
        }
      }
    }

    selectNode(itemId: number, scroll: boolean = false) {
        if (itemId > 0) {
            if (this.selectedNode && this.selectedNode.itemId == itemId) {
                // this node is already selected
                if (scroll) {
                    this.scroll();
                }
                return;
            }
            let found = false;
            for (let i = 0; i < this.data.length; i++) {
                const dataNode = this.data[i];
                if (dataNode.itemId == itemId) {
                    found = true;
                    if (this.selectedNode) {
                        this.selectedNode.isSelected = false;
                    }
                    this.selectedNode = dataNode;
                    this.selectedNode.isSelected = true;
                    if (scroll) {
                      this.scroll();
                    }
                    break;
                }
            }
            if (!found) {
              this.readAndFindItem(itemId);
            } else {
              this.selectPath = null;
              this.selectingNode = null;
            }
        } else {
            // all was selected
            if (this.selectedNode) {
                this.selectedNode.isSelected = false;
                this.selectedNode = undefined;
            }
        }
    }

  private readAndFindItem(itemId: number) {
    this.selectingNode = itemId;
    this._database.getPath(itemId).then(path => {
      if (path && path.length > 0 &&
        this.selectingNode &&
        this.selectingNode == path[path.length - 1].itemId) {
        this.selectPath = path;
        this.openNode(this.selectPath[0]);
      }
    })
  }

  private scroll() {

    if ( !this._navTree.show ) {
      return;
    }
    this.scrollAttempts = 0;
    this._scroll();
  }

  private _scroll() {
    this.scrollAttempts += 1;
    if ( this.scrollAttempts > 10 ) {
       return;
    }
    setTimeout(() => {
      let node = this.selectedNode;
      const element = document.querySelector('.selected-tree-item');
      if (element) {
          //element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
          //element.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'end'});  // always puts the selected at end of box
          element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
      } else {
        if (this.selectedNode) {
          this._scroll();
        }  // else the node got deselected no need to continue
      }
    }, 200);
  }

  refreshAll() {
      if ( this.data && this.data.length > 0) {
          let root = this.data[0]
          let rootTreeItem = this._database.getNode(root.itemId);
          if ( rootTreeItem ) {
            const newRootPromise = this._database.getPath(rootTreeItem.itemId);
            newRootPromise.then( result => {
              if ( result && result.length ) {
                let root = result[0];
                this._database.setNewRoot(root);
                this.data[0].itemId = root.itemId;
                this.data[0].item = root.itemName;
                this.refresh();
              }
            });
          }
      }
    }

  refresh() {
    this._treeControl.collapseAll();
      setTimeout( () => {
        if ( this.selectedNode ) {
          this.readAndFindItem(this.selectedNode.itemId);
        }
      }, 1000);
    }
}
