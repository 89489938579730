import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { AuthGuard } from '../auth.guard';

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
  // Pattern: Adding any components imported here to the array below allows you to add these components
  // to the feature module without importing all of the individual components there as well.
  // See the declarations array in this features module where you can declare the component using:
  // declarations: [featureModule.components]
  static components = [LoginComponent];
}
