import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { CountryCodeComponent } from './components/country-code/country-code.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NavigationTreeComponent } from './components/navigation-tree/navigation-tree.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';

@NgModule({
  declarations: [
    AlertDialogComponent,
    CountryCodeComponent,
    NavigationTreeComponent,
    PasswordRulesComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    MatExpansionModule,
    MatTreeModule,
  ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        TranslateModule,
        AlertDialogComponent,
        CountryCodeComponent,
        NavigationTreeComponent,
        PasswordRulesComponent,
    ]
})
export class SharedModule {
}
