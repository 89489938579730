
export interface IPasswordValues {
  currentPassword?: string;
  newPassword: string;
  confirmPassword: string;
  valid: boolean;
}

export const MIN_PASSWORD_LENGTH = 10;
export const MAX_PASSWORD_LENGTH = 50;

export interface IPasswordComponent {

    // These are the vars needed for the CustomValidation.has3Categories() method.
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasUnicode: boolean;
    hasNumber: boolean;
    hasSpecial: boolean;
    categoriesSatisfied: number;
}

export class IPasswordComponentHelper {
    static specialCharacters = '!#$%*&()+-./:;<=>?@[\\]^_{}~\'"`';

    static showPasswordError(errorType: string, form: any) {
    switch (errorType) {
      case 'needsMoreCategories':
        if (!form.password.errors.required &&
            form.password.errors.needsMoreCategories) {
          return true;
        }
        break;
      case 'hasMinMaxlength':
          if (!form.password.errors.required &&
            !form.password.errors.needsMoreCategories &&
            (form.password.errors.minlength)) {
          return true;
        }
        break;
    }
    return false;
  }
}

