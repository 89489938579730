import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';
import { SidenavModule } from './core/sidenav/sidenav.module';
import { TopToolbarModule } from './core/top-toolbar/top-toolbar.module';
import { MatNativeDateModule } from "@angular/material/core";
import { RouterModule } from "@angular/router";
import { HelpComponent } from "./help/help.component";
import {WINDOW_PROVIDERS} from "./core/providers/window.providers";

@NgModule({
  declarations: [
    AppComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    CoreModule,
    LoginModule,
    SharedModule,
    TopToolbarModule,
    SidenavModule,
    MatNativeDateModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
