<mat-sidenav-container class="container" *ngIf="isLoggedIn$ | async" [hasBackdrop]="hasBackdrop"
                       (backdropClick)="onBackdropClickOrEscape()">
  <mat-sidenav
    *ngIf="configLoaded"
    role="navigation"
    class="mat-elevation-z1 sidenav"
    (keydown.escape)="onBackdropClickOrEscape()"
    [ngClass]="{'open-nav-wide': currentNavState.isExpanded && showTree && treeEnabled,
              'open-nav': currentNavState.isExpanded && !showTree && !treeEnabled,
              'close-nav-wide': !currentNavState.isExpanded && !showTree && treeEnabled,
              'close-nav': !currentNavState.isExpanded && !showTree && !treeEnabled}"
    [disableClose]="disableClose"
    [opened]="currentNavState.isOpened">

    <div id="inner-nav-wrapper" class="inner-nav-wrapper">
      <div>
        <mat-nav-list>
                <a mat-list-item *ngFor="let menuItem of menu; let i = index;" class="page-link"
                   routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"
                   (click)="onNavItemClick()"
                   [routerLink]="'/' + menuItem.route | lowercase"
                   [matTooltipDisabled]="currentNavState.isExpanded"
                   [matTooltip]="menuItem.displayName | translate" matTooltipShowDelay="500"
                   matTooltipPosition="right">
                  <i class="mdi nav-icon" [ngClass]="menuItem.iconName | lowercase"></i>
                  <span [ngClass]="{'fade-in': currentNavState.isExpanded, 'fade-out': !currentNavState.isExpanded}"
                        class="nav-title">
                      {{menuItem.displayName | translate}}
                    </span>
                </a>
        </mat-nav-list>
      </div>
      <div *ngIf="showTree" class="tree-container">
        <bsc-navigation-tree
          [dataProvider]="treeDataProvider"
          [selectItem]="selectItem"
          [treeRefresh]="treeRefresh"
          (nodeItemIdSelected)="onTreeSelect($event)">
        </bsc-navigation-tree>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content *ngIf="configLoaded" class="sidenav-content" [ngClass]="currentNavState | contentCss:closedMediaStates">
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
