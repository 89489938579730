export enum ErrorCode {
  // authorization errors
  BAD_DATA_EXCEPTION = 'BAD_DATA_EXCEPTION',
  GENERIC_EXCEPTION = 'GENERIC_EXCEPTION',
  MISSING_REQUIRED_DATA = 'MISSING_REQUIRED_DATA',
  INVALID_HEADER = 'INVALID_HEADER',
  TOKEN_SIGNATURE_FAILURE = 'TOKEN_SIGNATURE_FAILURE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_AUTH_USER_ID = 'INVALID_AUTH_USER_ID',
  LOGIN_VERIFY_FAIL = 'LOGIN_VERIFY_FAIL',
  TOKEN_CREATE_FAIL = 'TOKEN_CREATE_FAIL',

  // LOGIN ERRORS
  GENERAL_LOGIN_FAILURE = 'GENERAL_LOGIN_FAILURE',
  TWO_FACTOR_CODE_REQUIRED = "TWO_FACTOR_CODE_REQUIRED",

  // USER ERRORS
  PASSWORD_VALIDATION_FAIL = 'PASSWORD_VALIDATION_FAIL',

  // Analytics Errors
  NO_DEVICES_FOUND = "NO_DEVICES_FOUND"
}

export interface IApiResponse<T> {
  success: boolean;
  data: T;
  error_code?: ErrorCode;
}

export interface IGenericResponse {
  success: boolean;
  data: Record<string, string>;
  error_code?: ErrorCode;
}
