import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { asyncScheduler, Observable, of, Subject, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../services/sidenav.service';
import { skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { IOrganization, OrganizationService, organizationType, OrgType } from "../services/organization.service";
import { SelectedOrganizationService } from "../services/selected-organization.service";
import { ConfigurationService } from '../services/configuration.service';
import { TreeDataProvider } from './treeDataProvider';
import { TreeItem } from '../../shared/components/navigation-tree/tree-types';

export interface ICurrentNavState {
  activeMedia: string;
  isOpened: boolean;
  isExpanded: boolean;
  showTree: boolean;
}

export interface INavItem {
  displayName: string;
  disabled?: boolean;
  types: organizationType[];  // List of types for which this INavItem will be displayed.
  iconName: string;
  route?: string;
  children?: INavItem[];
}

// displayName should be the translation ID
export let menu: INavItem[] = [
  {
    displayName: 'sidenav.dashboard',
    iconName: 'mdi-view-dashboard-outline',
    route: 'dashboard',
    types: [OrgType.INTERNAL, OrgType.MSSP, OrgType.MSP, OrgType.CUSTOMER],  // admin, MSSP, MSP, customer
  },
  {
    displayName: 'sidenav.organizations',
    iconName: 'mdi-domain',
    route: 'organizations',
    types: [OrgType.INTERNAL, OrgType.MSSP, OrgType.MSP],  // admin, MSSP, MSP
  },
  {
    displayName: 'sidenav.users',
    iconName: 'mdi-account-multiple-outline',
    route: 'users',
    types: [OrgType.INTERNAL, OrgType.MSSP, OrgType.MSP, OrgType.CUSTOMER],  // admin, MSSP, MSP, customer
  },
  {
    displayName: 'sidenav.devices',
    iconName: 'mdi-monitor-multiple',
    route: 'devices',
    types: [OrgType.INTERNAL, OrgType.MSSP, OrgType.MSP, OrgType.CUSTOMER],  // admin, MSSP, MSP, customer
  },
  {
    displayName: 'sidenav.reports',
    iconName: 'mdi-chart-line',
    route: 'reports',
    types: [OrgType.INTERNAL, OrgType.MSSP, OrgType.MSP, OrgType.CUSTOMER],  // admin, MSSP, MSP, customer
  }
];

@Component({
  selector: 'bsc-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav | undefined;
  private destroy$: Subject<null> = new Subject<null>();
  private mediaWatcher: Subscription;
  menu!: INavItem[];
  isLoggedIn$: Observable<boolean> = of(false);
  closedMediaStates = ['xs', 'lt-sm', 'sm', 'lt-md'];
  hasBackdrop = false;
  disableClose = true;
  currentNavState: ICurrentNavState = {
    activeMedia: '',
    isOpened: true,
    isExpanded: true,
    showTree: false
  };

  treeDataProvider: TreeDataProvider;
  selectItem: TreeItem | undefined;
  treeEnabled = false;
  showTree = false;
  loggedInOrg: IOrganization | undefined;
  treeRefresh: number = 0;
  selectedOrg: IOrganization | null = null;
  configLoaded = false;

  constructor(public authService: AuthService,
              private sidenavService: SidenavService,
              public media: MediaObserver,
              public selectedOrgService: SelectedOrganizationService,
              private organizationService: OrganizationService,
              private configurationService: ConfigurationService,
              private cdRef: ChangeDetectorRef) {

    this.treeDataProvider = new TreeDataProvider(this.organizationService, this);

    this.mediaWatcher = this.media
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((mediaChange: MediaChange[]) => {
        this.handleMediaChange(mediaChange[0]);
      });

    this.selectedOrgService.selectedOrganization.pipe(
      tap( org => {
          this.selectedOrg = org;
          this.notifyTree(org);
      }),
      switchMap(org => {
        if (org?.id) {
          return of(org);
        } else {
          return this.authService.loggedInOrg;
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe(org => {
      this.menu = menu.filter(navItem => navItem.types.find(orgType => orgType === org?.type) !== undefined);
    });

    this.authService.loggedInOrg
        .pipe(takeUntil(this.destroy$))
        .subscribe(loggedInOrg => {
          if ( loggedInOrg ) {
            this.loggedInOrg = loggedInOrg;
          }
      });

    this.organizationService.newOrganizations
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.treeRefresh++;
    });

  }

  private notifyTree(org: IOrganization | null) {
    if ( !this.treeEnabled ) {
      // don't process this notification because the tree can't be loaded
      return;
    }

    if (org != null && org.id) {
      this.authService.loggedInOrg
        .pipe(take(1))
        .subscribe(() => {
          let canHaveChildren = true;
          if (org.type == OrgType.CUSTOMER) {
            canHaveChildren = false;
          }

          let itemId = -1;  // default is deselect
          if (org && org.id) {
            if (itemId) {
              itemId = org.id;
            }
          }
          this.selectItem = {
            'itemName': org.name,
            'itemId': itemId,
            'canHaveChildren': canHaveChildren
          }
        });
    } else {
      // org is null
      this.selectItem = {
            'itemName': 'deselect',
            'itemId': -1,
            'canHaveChildren': false
      }
    }
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.subscribeToSideNavService();
    this.configurationService.getApiConfiguration().then( apiConfiguration => {
          this.configLoaded = true;
          if ( apiConfiguration && apiConfiguration.navigationTreeEnabled ) {
            this.treeEnabled = true;
            if (!this.closedMediaStates.includes(this.currentNavState.activeMedia)) {
              this.showTree = true;
              this.open();
            }
          }
          this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeToSideNavService(): void {
    this.sidenavService.getToggleSubject()
      .pipe(
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.toggle();
      });

  }

  onNavItemClick() {
    if (this.media.isActive('lt-md')) {
      this.close();
    }
  }

  onBackdropClickOrEscape() {
    if (this.media.isActive('lt-md')) {
      this.currentNavState = {...this.currentNavState, isOpened: false, isExpanded: true, showTree: false};
    }
  }

  toggle(): void {
    let isExpanded = this.currentNavState.isExpanded;
    let isOpened = this.currentNavState.isOpened;
    if (this.media.isActive('lt-md')) {
      isExpanded = true;
      this.currentNavState.isOpened ? this.close() : this.open();
      isOpened = !isOpened;
    } else {
      isOpened = true;
      isExpanded = !isExpanded;
      this.showTree = isExpanded && this.treeEnabled;

    }
    this.currentNavState = {...this.currentNavState, isOpened: isOpened, isExpanded: isExpanded, showTree: this.showTree};
    asyncScheduler.schedule(() => this.notifyTree(this.selectedOrg));
  }

  close(): void {
    this.currentNavState = {...this.currentNavState, isOpened: false, showTree: this.showTree};
    this.sidenav?.close();
  }

  open(): void {
    this.showTree = this.currentNavState.isOpened && this.currentNavState.isExpanded && this.treeEnabled;
    this.currentNavState = {...this.currentNavState, isOpened: true, showTree: this.showTree};
    this.sidenav?.open();
  }

  private handleMediaChange(mediaChange: MediaChange) {
    this.showTree = this.currentNavState.isOpened && this.currentNavState.isExpanded && this.treeEnabled;
    if (this.currentNavState.activeMedia) {
      if (this.media.isActive('lt-md')) {
        this.hasBackdrop = true;
        this.disableClose = false;
        this.close();
      } else {
        // Set isExpanded=true to fix pushing content out too far
        // when coming from a closed nav menu with it not expanded.
        if (this.closedMediaStates.includes(this.currentNavState.activeMedia) && mediaChange.mqAlias === 'lt-md') {
          this.currentNavState = {...this.currentNavState, isExpanded: true, showTree: this.showTree};
        }
        this.hasBackdrop = false;
        this.disableClose = true;
        this.open();
      }
    } else {
      if (this.media.isActive('lt-md')) {
        this.hasBackdrop = true;
        this.disableClose = false;
      }
    }
    this.currentNavState = {...this.currentNavState, activeMedia: mediaChange.mqAlias, showTree: this.showTree};
    this.notifyTree(this.selectedOrg);
  }

  onTreeSelect(orgId: number) {
    if ( !this.treeEnabled ) {
      return;
    }
    this.selectedOrgService.treeNodeSelected.next(orgId.toString());
  }
}
