export interface ILanguage {
  name: string;
  code: string;
}

export interface ILanguageResult {
  supported_languages: ILanguage[];
}

export const supportedLanguages: ILanguage[] = [
  {"name": "English", "code": "en"},
  {"name": "Español", "code": "es"},
  {"name": "Français", "code": "fr"},
  {"name": "Deutsche", "code": "de"},
  {"name": "日本", "code": "ja"},
  {"name": "中文", "code": "zh"},
  {"name": "中文（简体）", "code": "zh-CN"},
  {"name": "中文（繁體）", "code": "zh-TW"}
];
