import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { ProfileInfoPipe } from './profile-menu/profile-info.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { TopToolbarComponent } from './top-toolbar.component';
import { EnsureModuleLoadedOnceGuard } from '../ensure-module-loaded-once.guard';
import { CoreModule } from '../core.module';
import { SidenavService } from '../services/sidenav.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrganizationSelectorComponent } from './organization-selector/organization-selector.component';
import { SharedModule } from '../../shared/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrganizationSelectorInterceptor } from './organization-selector/organization-selector.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    TopToolbarComponent,
    ProfileMenuComponent,
    ProfileInfoPipe,
    OrganizationSelectorComponent
  ],
  imports: [
    SharedModule,
    MatMenuModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatSlideToggleModule
  ],
  exports: [
    TopToolbarComponent
  ],
  providers: [
    SidenavService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationSelectorInterceptor,
      multi: true,
    },
  ]
})
export class TopToolbarModule extends EnsureModuleLoadedOnceGuard {
  // Ensures that TopToolbarModule is only loaded into AppModule
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
