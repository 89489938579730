import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectedOrganizationService } from '../../services/selected-organization.service';

@Injectable()
export class OrganizationSelectorInterceptor implements HttpInterceptor {

  constructor(private selectedOrgService: SelectedOrganizationService) {

  }

  // The gui code can put the header 'x-ignore-selected-org' into the request, and it will make this
  // interceptor ignore the request and the selected org will not be added to the request.  This allows
  // the gui to make calls for the REAL user and ignore the selected user.  (For instance, get MY org).
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const selectedOrgId = this.selectedOrgService.getSelectedOrganizationId();
    const ignoreSelected = request.headers.get('x-ignore-selected-org')
    if ( ignoreSelected == null ) {
      if (selectedOrgId && typeof (+selectedOrgId) === 'number' && +selectedOrgId > 0) {
        request = request.clone({
          url: request.url + (request.url.indexOf('?') > -1 ? '&' : '?') + `org=${selectedOrgId}`
        });
      }
    }

    return next.handle(request);
  }
}
