<div class="container">
  <ng-container *ngIf="themeClass$ | async as themeClass">
    <span class="logo" [ngClass]="themeClass"></span>
  </ng-container>

  <h1>{{'dashboard.help.title' | translate}}</h1>

  <h2 id="devices">{{'dashboard.help.devices.title' | translate}}</h2>
  <h3>{{'dashboard.help.devices.online-agents-title' | translate}}</h3>
  <p>{{'dashboard.help.devices.online-agents-text' | translate}}</p>
  <h3>{{'dashboard.help.devices.online-agents-title' | translate}}</h3>
  <p>{{'dashboard.help.devices.online-agents-text' | translate}}</p>

  <h2 id="blocked-attacks">{{'dashboard.help.blocked-attacks.title' | translate}}</h2>
  <p>{{'dashboard.help.blocked-attacks.text' | translate}}</p>

  <h2 id="blocked-mc">{{'dashboard.help.blocked-mc.title' | translate}}</h2>
  <p>{{'dashboard.help.blocked-mc.text' | translate}}</p>

  <h2 id="ndr-alerts">{{'dashboard.help.ndr-alerts.title' | translate}}</h2>
  <p>{{'dashboard.help.ndr-alerts.text' | translate}}</p>

  <h2 id="vulnerability-counts">{{'dashboard.help.vulnerability-counts.title' | translate}}</h2>
  <h3>{{'dashboard.help.vulnerability-counts.critical-title' | translate}}</h3>
  <p>{{'dashboard.help.vulnerability-counts.critical-text' | translate}}</p>
  <h3>{{'dashboard.help.vulnerability-counts.high-title' | translate}}</h3>
  <p>{{'dashboard.help.vulnerability-counts.high-text' | translate}}</p>
  <h3>{{'dashboard.help.vulnerability-counts.medium-title' | translate}}</h3>
  <p>{{'dashboard.help.vulnerability-counts.medium-text' | translate}}</p>
  <h3>{{'dashboard.help.vulnerability-counts.low-title' | translate}}</h3>
  <p>{{'dashboard.help.vulnerability-counts.low-text' | translate}}</p>

  <h2 id="post-xdr-risk-score">{{'dashboard.help.post-xdr-risk-score.title' | translate}}</h2>
  <p>{{'dashboard.help.post-xdr-risk-score.text' | translate}}</p>

  <h2 id="raw-risk-score">{{'dashboard.help.raw-risk-score.title' | translate}}</h2>
  <p>{{'dashboard.help.raw-risk-score.text' | translate}}</p>

  <h2 id="risk-score-trend">{{'dashboard.help.risk-score-trend.title' | translate}}</h2>
  <h3>{{'dashboard.help.post-xdr-risk-score.title' | translate}}</h3>
  <p>{{'dashboard.help.post-xdr-risk-score.text' | translate}}</p>
  <h3>{{'dashboard.help.raw-risk-score.title' | translate}}</h3>
  <p>{{'dashboard.help.raw-risk-score.text' | translate}}</p>
</div>
