<main>
  <div *ngIf="help; else mainApp">
    <router-outlet></router-outlet>
  </div>
  <ng-template #mainApp>
    <div *ngIf="isLoggedIn$ | async">
      <bsc-top-toolbar></bsc-top-toolbar>
      <bsc-sidenav></bsc-sidenav>
    </div>
    <div *ngIf="!(isLoggedIn$ | async)">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</main>
