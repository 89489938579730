import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SidenavService } from '../services/sidenav.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TopToolbarService } from '../services/top-toolbar.service';
import { IOrganization, OrganizationService } from '../services/organization.service';
import { SelectedOrganizationService } from '../services/selected-organization.service';
import { IUser } from '../services/user.service';
import { Theme, ThemeService } from '../services/theme.service';

@Component({
  selector: 'bsc-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss']
})
export class TopToolbarComponent implements OnInit, OnDestroy {
  private destroy$: Subject<null> = new Subject<null>();
  loggedInUser: IUser | null = null;
  organizations: IOrganization[] = [];
  breadcrumb = '';
  theme: Observable<Theme>;

  constructor(private authService: AuthService,
              private sideNavService: SidenavService,
              private toolbarService: TopToolbarService,
              private organizationService: OrganizationService,
              private selectedOrganizationService: SelectedOrganizationService,
              private themeService: ThemeService) {
    this.theme = themeService.getTheme();
  }

  ngOnInit(): void {
    this.authService.loggedInUser
      .pipe(takeUntil(this.destroy$))
      .subscribe(loggedInUser => {
      this.loggedInUser = loggedInUser;
    });

    // subscribe to all refreshes of the organizations list.
    this.organizationService.newOrganizations.pipe(
      switchMap(() => {
        return this.organizationService.readAllRecursive();
      })
    ).subscribe(result => {
      this.organizations = [...result.data].sort(
        (a, b) => a.name.localeCompare(b.name));
    });

    this.toolbarService.breadcrumb
      .pipe(takeUntil(this.destroy$))
      .subscribe(breadcrumb => this.breadcrumb = breadcrumb);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSignOut() {
    this.authService.logout();
  }

  onSelectedOrgChanged(selectedOrg: string) {
    this.selectedOrganizationService.setSelectedOrganizationId(selectedOrg);
    // @ts-ignore
    const org: IOrganization = this.organizations.find( o => o.id.toString() === selectedOrg )
    this.selectedOrganizationService.setSelectedOrganization(org)
  }

  toggleSidenav() {
    this.sideNavService.toggle();
  }

}
