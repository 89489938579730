import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode } from '../../shared/types/api.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  handleError(errorResponse: HttpErrorResponse) {
    let errorMessage;

    if (errorResponse.error instanceof ErrorEvent) {
      errorMessage = `${this.translate.instant('errors.error-occurred')}: ${errorResponse.error.message}`;
    } else {
      errorMessage = this.getServerErrorMessage(errorResponse);
    }

    if (errorMessage) {
      this.snackBar.open(
        this.translate.instant(errorMessage),
        this.translate.instant('common.ok'),
        {duration: 0, verticalPosition: "top"}
      );
      console.log(errorMessage);
    }
  }

  private getServerErrorMessage(errorResponse: HttpErrorResponse): string | null {
    let serverErrorMessage: string | null = this.translate.instant('common.unknown-error');

    if (errorResponse.status >= 500) {
      serverErrorMessage = this.translate.instant('errors.internal-server-error');
    } else {
      if (errorResponse.error?.error_code) {
        switch (errorResponse.error.error_code) {
          case ErrorCode.BAD_DATA_EXCEPTION:
            serverErrorMessage = this.translate.instant('errors.bad-data');
            break;
          case ErrorCode.GENERAL_LOGIN_FAILURE:
            serverErrorMessage = this.translate.instant('errors.general-login-fail');
            break;
          case ErrorCode.GENERIC_EXCEPTION:
            serverErrorMessage = this.translate.instant('errors.generic-exception');
            break;
          case ErrorCode.INVALID_HEADER:
            serverErrorMessage = this.translate.instant('errors.invalid-header');
            break;
          case ErrorCode.INVALID_AUTH_USER_ID:
            serverErrorMessage = this.translate.instant('errors.invalid-user-id');
            break;
          case ErrorCode.INVALID_TOKEN:
            serverErrorMessage = this.translate.instant('errors.invalid-token');
            break;
          case ErrorCode.LOGIN_VERIFY_FAIL:
            serverErrorMessage = null;
            break;
          case ErrorCode.TOKEN_CREATE_FAIL:
            serverErrorMessage = this.translate.instant('errors.authentication-fail');
            break;
          case ErrorCode.TOKEN_SIGNATURE_FAILURE:
            serverErrorMessage = this.translate.instant('errors.invalid-token');
            break;
          case ErrorCode.PASSWORD_VALIDATION_FAIL:
            serverErrorMessage = this.translate.instant('errors.invalid-password');
            break;
          case ErrorCode.MISSING_REQUIRED_DATA:
            serverErrorMessage = this.translate.instant('errors.required-data-missing');
            break;
          default:
            serverErrorMessage = this.translate.instant('common.unexpected-error');
            break;
        }
      }
    }

    return serverErrorMessage;
  }
}
