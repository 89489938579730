import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { SidenavComponent } from './sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AuthService } from '../services/auth.service';
import { EnsureModuleLoadedOnceGuard } from '../ensure-module-loaded-once.guard';
import { CoreModule } from '../core.module';
import { RouterModule } from '@angular/router';
import { SidenavService } from '../services/sidenav.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContentCssPipe } from './content-css.pipe';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    SidenavComponent,
    ContentCssPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatListModule,
    MatSidenavModule,
    RouterModule,
    MatTooltipModule
  ],
  exports: [
    SidenavComponent
  ],
  providers: [
    AuthService,
    SidenavService
  ]
})
export class SidenavModule extends EnsureModuleLoadedOnceGuard {
  // Ensures that SidenavModule is only loaded into AppModule
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
