import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';
import { IApiResponse, IGenericResponse } from '../../shared/types/api.types';
import { IUserExistsResponse } from "./user.service";
import { catchError, map } from "rxjs/operators";

// organizationType: 0=internal, 1 = MSSP, 2=MSP, 3=standard customer'
export type organizationType = OrgType.INTERNAL | OrgType.MSSP | OrgType.MSP | OrgType.CUSTOMER;

export enum OrgType {
    INTERNAL = 0,
    MSSP = 1,
    MSP = 2,
    CUSTOMER = 3
}

export interface IEditOrg {
  org: IOrganization,
  readonly: boolean,
  isAdmin: boolean
}

export interface IOrganization {
  id?: number;
  parent_id?: number;
  type?: organizationType;
  name: string;
  customerId: string;
  address?: string;
  country?: string;
  city?: string;
  state?: string;
  zip?:string;
  contact_name?: string;
  contact_email?: string;
  license_count?: number;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends RestService<IOrganization, IGenericResponse> {

  // This is an observable that the TopToolbarComponent subscribes to trigger fetching new copies
  // of the organizations.  Don't use this for anything but the toolbar.
  public newOrganizations: BehaviorSubject<undefined> = new BehaviorSubject<undefined>(undefined);

  constructor(protected httpClient: HttpClient, protected configurationService: ConfigurationService) {
    super(
      httpClient,
      configurationService,
      '/api/v1/organizations'
    )
  }

  readChildren(orgId: number): Observable<IApiResponse<IOrganization[]>> {
    const apiUrl = `${this.APIUrl}?org=${orgId}`;
    const httpOptions = {
          headers: new HttpHeaders({
            'x-ignore-selected-org':  "true",
          })
        };

    return this.httpClient.get<IApiResponse<IOrganization[]>>(apiUrl, httpOptions)
      .pipe(
        map((response: IApiResponse<IOrganization[]>) => response),
        catchError(this.handleError)
      );
  }

  readAllRecursive(options: Object = {}): Observable<IApiResponse<IOrganization[]>> {
    const apiUrl = `${this.APIUrl}?all=true`;
    return this.httpClient.get<IApiResponse<IOrganization[]>>(apiUrl, options)
      .pipe(
        map((response: IApiResponse<IOrganization[]>) => response),
        catchError(this.handleError)
      );
  }

  readAllChildren(options: Object = {}): Observable<IApiResponse<IOrganization[]>> {
    const apiUrl = `${this.APIUrl}?recursive=true`;
    return this.httpClient.get<IApiResponse<IOrganization[]>>(apiUrl, options)
      .pipe(
        map((response: IApiResponse<IOrganization[]>) => response),
        catchError(this.handleError)
      );
  }

  deleteList(ids: number[], options: Object = {}): Observable<IApiResponse<IGenericResponse>> {
    const apiUrl = `${this.APIUrl}/deletelist`;

    const body_json = {
      'org_list': ids
    }

    return this.httpClient.post<IApiResponse<IGenericResponse>>(apiUrl, body_json, options)
      .pipe(
        catchError(this.handleError)
      );
  }

  customerIdExists(customerId: string): Observable<IApiResponse<IUserExistsResponse>> {
    const suffix = this.configurationService.getUrl(`/api/v1/customer-id-exists?customerId=${customerId}`);
    return this.httpClient.get<IApiResponse<IUserExistsResponse>>(suffix);
  }

  public organizationsChanged() {
    this.newOrganizations.next(undefined);
  }

  readCustomers(options: Object = {}): Observable<IApiResponse<IOrganization[]>> {
    const apiUrl = this.configurationService.getUrl(`/api/v1/customers?recursive=true`);
    return this.httpClient.get<IApiResponse<IOrganization[]>>(apiUrl, options)
      .pipe(
        map((response: IApiResponse<IOrganization[]>) => response),
        catchError(this.handleError)
      );
  }

  getOrgAndParents(orgId: number): Observable<IApiResponse<IOrganization[]>> {
    const apiUrl = `${this.APIUrl}/hierarchy/${orgId}`;
    const httpOptions = {
          headers: new HttpHeaders({
            'x-ignore-selected-org':  "true",
          })
        };

    return this.httpClient.get<IApiResponse<IOrganization[]>>(apiUrl, httpOptions)
      .pipe(
        map((response: IApiResponse<IOrganization[]>) => response),
        catchError(this.handleError)
      );
  }
}
