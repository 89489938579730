import { Injectable } from '@angular/core';
import { DataProvider, DynamicFlatNode, TreeItem } from './tree-types';

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({providedIn: 'root'})
export class DynamicDatabase {
    dataProvider?: DataProvider;

    setProvider(provider: DataProvider) {
        this.dataProvider = provider;
    }

    idHierarchyMap = new Map<number, TreeItem[]>([]);
    idToItemMap = new Map<number, TreeItem>([]);

    rootLevelNodes: TreeItem[] = [];
    count = 0;

    setInitialData(rootNodes: TreeItem[]) {
        this.rootLevelNodes = rootNodes;
        for (let i = 0; i < rootNodes.length; i++) {
            this.idToItemMap.set(rootNodes[0].itemId, rootNodes[0]);
        }
        this.count = rootNodes.length;
    }

    /** Initial data from database */
    initialData(): DynamicFlatNode[] {
        return this.rootLevelNodes.map(node => new DynamicFlatNode(node.itemName, node.itemId, 0, node.canHaveChildren));
    }


    resolve_array(resolve: { (value: TreeItem[] | PromiseLike<TreeItem[]>): void; (arg0: TreeItem[]): void; },
                  treeItems: TreeItem []) {
      if ( treeItems ) {
        treeItems.sort((a, b) => { return a.itemName.localeCompare(b.itemName); })
      }
      resolve(treeItems);
    }

    getChildren(itemId: number): Promise<TreeItem[]> {
        // @ts-ignore
        return new Promise((resolve, reject) => {
            if (this.idHierarchyMap.has(itemId)) {
                // try to get children from data already read
                let children: TreeItem[] | undefined = this.idHierarchyMap.get(itemId);
                if (children) {
                    this.resolve_array(resolve, children);
                    return;  // short circuit rest of method
                }
            }
            if (this.dataProvider) {
                this.dataProvider.getChildren(itemId).then(children => {
                    this.idHierarchyMap.set(itemId, children);
                    for (let i = 0; i < children.length; i++) {
                        const child = children[i];
                        this.idToItemMap.set(child.itemId, child);
                    }
                    this.resolve_array(resolve, children);
                });
            } else {
              this.resolve_array(resolve, []);
            }
        })
    }

    isExpandable(itemId: number): boolean {
        if (this.idToItemMap.has(itemId)) {
            // @ts-ignore
            return this.idToItemMap.get(itemId).canHaveChildren;
        } else {
            return false;
        }
    }

    getPath(itemId: number): Promise<TreeItem[]> {
        if (this.dataProvider) {
            return this.dataProvider.getPath(itemId);
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    }

  getNode(itemId: number): TreeItem | undefined{
      return this.idToItemMap.get(itemId);
  }

  setNewRoot(newRootElement: TreeItem) {
    this.idHierarchyMap.clear();
    this.idToItemMap.clear();
    this.idToItemMap.set(newRootElement.itemId, newRootElement);
  }
}
