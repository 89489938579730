import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface IAlertDialogData {
  title: string;
  content: string;
  additionalContent: Array<string>;
  acceptBtnName: string;
  rejectBtnName: string;
  showRejectBtn: boolean;
}

@Component({
  selector: 'bsc-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: IAlertDialogData,
      private translate: TranslateService) {

    this.translate.get(['common.ok', 'common.cancel']).subscribe(vals => {
        data.rejectBtnName = data.rejectBtnName ? data.rejectBtnName : vals['common.cancel'];
        data.acceptBtnName = data.acceptBtnName ? data.acceptBtnName : vals['common.ok'];
    });

  }

  ngOnInit(): void {
  }

}
