import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../types/password';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss']
})
export class PasswordRulesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<void>) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  readonly MIN_PASSWORD_LENGTH = MIN_PASSWORD_LENGTH;
  readonly MAX_PASSWORD_LENGTH = MAX_PASSWORD_LENGTH;
}
