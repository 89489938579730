import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpComponent } from "./help/help.component";
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'login'},
  {path: 'help', component: HelpComponent, data: {requiresLogin: false}, canActivate: [AuthGuard]},
  {path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
  {path: 'devices', loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule)},
  {path: 'organizations', loadChildren: () => import('./orgs/orgs.module').then(m => m.OrgsModule)},
  {path: 'reset', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)},
  {path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)},
  {path: 'profile/password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule)},
  {path: 'profile/contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
  {path: 'profile/two-factor', loadChildren: () => import('./two-factor/two-factor.module').then(m => m.TwoFactorModule)},
  {path: '**', pathMatch: 'full', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
