import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from '../../services/user.service';
import { Theme, ThemeService } from '../../services/theme.service';
import { Observable } from 'rxjs';
import {ConfigurationService} from "../../services/configuration.service";

@Component({
  selector: 'bsc-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  @Input() user: IUser | null = null;
  @Output() signOutClick = new EventEmitter();

  theme: Observable<Theme>;

  constructor(private themeService: ThemeService,
              public readonly configurationService: ConfigurationService) {
    this.theme = themeService.getTheme();
  }

  ngOnInit(): void {
  }

  onSignOut() {
    this.signOutClick.emit();
  }

  onThemeClicked(event: Event) {
    event.stopPropagation();
  }

  onThemeChanged(checked: boolean) {
    const theme = checked ? 'dark' : 'light';
    this.themeService.setTheme(theme);
  }

}
