import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApiResponse } from '../../shared/types/api.types';
import {WindowService} from "./window.service";
import {Theme, ThemeService} from "./theme.service";

interface IDomain {
  title: string;
  showThemeSelector: boolean;
  defaultTheme: Theme;
  translationPostfix: string;
}

interface IDomainMap {
  [key: string]: IDomain;
}

interface IJsonConfig {
  configuration: string;
  apiUrl: string;
  domainMap: IDomainMap;
}

interface IApiConfig {
  navigationTreeEnabled: boolean;
}

export const configurationServiceFactory = (configurationService: ConfigurationService) => {
  return () => configurationService.load();
}

@Injectable({providedIn: 'root'})
export class ConfigurationService {
  configuration: string | null = null;
  apiUrl: string | null = null;
  apiConfiguration: IApiConfig | undefined;
  title: string = "Blueshft Console";
  showThemeSelector = false;
  translationPostfix!: string;

  constructor(private httpClient: HttpClient,
              private windowService: WindowService,
              private themeService: ThemeService) {
  }

  load(): Promise<IJsonConfig> {
    const promise = this.httpClient.get<IJsonConfig>('/assets/configuration.json')
      .toPromise()
      .then((config: IJsonConfig) => {
        this.configuration = config.configuration || 'production';
        this.apiUrl = config.apiUrl;

        const hostname = this.windowService.getHostName();
        const configKeys = Object.keys(config);
        if (configKeys.includes('domainMap') && Object.keys(config.domainMap).includes(hostname)) {
          const domainConfig: IDomain = config.domainMap[hostname];
          this.title = domainConfig.title;
          this.showThemeSelector = domainConfig.showThemeSelector;
          this.themeService.setTheme(domainConfig.defaultTheme);
          this.translationPostfix = domainConfig.translationPostfix;
        }
        return config;
      });
    return promise;
  }

  getUrl(suffix: string): string {
    const url = this.apiUrl || '';
    const suffixWithSlash = suffix.startsWith('/') ? suffix : `/${suffix}`;
    return `${url}${suffixWithSlash}`;
  }

  reset() {
    this.apiConfiguration = undefined;
  }

  getApiConfiguration() : Promise<IApiConfig> {
    // @ts-ignore
    return new Promise<IApiConfig>(resolve => {
      if (this.apiConfiguration)
        resolve(this.apiConfiguration);
      else {
        const url = this.getUrl('/api/v1/configuration')
        return this.httpClient.get<IApiResponse<IApiConfig>>(url).subscribe(apiConfig => {
          this.apiConfiguration = apiConfig.data;
          resolve(this.apiConfiguration);
        },
    error => {
              console.error("could not obtain configuration");
              this.apiConfiguration = {
                navigationTreeEnabled: false
              }
              resolve(this.apiConfiguration);
        })
      }
    })
  }
}
