<span *ngIf="user">
  <div class="profile-button-wrapper" [matMenuTriggerFor]="menu">
    <div fxShow fxHide.lt-lg class="name-wrapper">
      <div class="name display-name">{{(user | profileInfo)?.displayName}}</div>
      <div *ngIf="user.email" class="name email">{{user.email}}</div>
    </div>
    <button class="profile-button" id="menu" aria-label="Profile menu button"
            [matTooltip]="(user | profileInfo)?.displayName + ' <' + user.email + '>'"
            matTooltipShowDelay="500" matTooltipPosition="below">
          <span class="initials-wrapper">
            <span class="initials-text">{{(user | profileInfo)?.initials}}</span>
          </span>
    </button>
  </div>
  <mat-menu class="profile-menu" #menu="matMenu" xPosition="before">
    <ng-container *ngIf="configurationService.showThemeSelector">
      <div style="padding: 11px 16px;">
          <i class="mdi mdi-theme-light-dark theme-icon"></i>
          <span style="margin-left: 10px;">
              <mat-slide-toggle
                labelPosition="after"
                aria-labelledby="Theme selector"
                color="primary"
                [disableRipple]="true"
                (click)="onThemeClicked($event)"
                (change)="onThemeChanged($event.checked)"
                [checked]="(theme | async) === 'dark'">
                  {{(theme | async) === 'dark' ? 'Dark Theme' : 'Light Theme'}}
              </mat-slide-toggle>
          </span>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <a routerLink="profile/contact" id="editProfileBtn" mat-menu-item>
      <i class="mdi mdi-account menu-item"></i>
      <span>{{'top-toolbar.manage-profile' | translate}}</span>
    </a>
    <a routerLink="profile/password" mat-menu-item>
      <i class="mdi mdi-lock menu-item"></i>
      <span>{{'top-toolbar.change-password' | translate}}</span>
    </a>
    <a routerLink="profile/two-factor" mat-menu-item>
      <i class="mdi mdi-key menu-item"></i>
      <span>{{'top-toolbar.manage-2fa' | translate}}</span>
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onSignOut()">
      <i class=" mdi mdi-logout menu-item"></i>
      <span>{{'top-toolbar.sign-out' | translate}}</span>
    </button>
  </mat-menu>
</span>
