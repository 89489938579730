import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (location.pathname === '/help') {
      return true;
    }
    const requiresLogin = route.data.requiresLogin || false;
    return this.checkLoggedIn(requiresLogin, state.url);
  }

  checkLoggedIn(requiresLogin: boolean, url: string): boolean {
    // Routes only for logged in users, redirect to login
    if (requiresLogin) {
      if (this.authService.isAuthenticated()) {
        return true;
      }
      this.authService.redirectUrl = url;
      this.router.navigate(['login']);
      return false;
    }

    // Routes only for non-logged in users, redirect to dashboard
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['dashboard']);
      return false;
    }

    return true;
  }

}
