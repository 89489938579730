import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
import { JwtModule } from '@auth0/angular-jwt';
import { ConfigurationService, configurationServiceFactory } from './services/configuration.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
import { TopToolbarModule } from './top-toolbar/top-toolbar.module';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { supportedLanguages } from '../shared/types/language';

export function getToken(): string | null {
  return localStorage.getItem('auth_token');
}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService) {
  return async () => {
    const languageCodes: string[] = supportedLanguages.map(n => n.code);
    // zh and zh-CN are simplified Chinese, we want to support both language codes,
    // but don't want to duplicate language files, so replace zh-CN with zh in localStorage
    // and the translateService.
    translate.addLangs(languageCodes.filter(c => c !== 'zh-CN'));
    let defaultLang: string = languageCodes.find(lang => lang === window.navigator.language) || 'en';
    defaultLang = defaultLang.replace("zh-CN", "zh");
    localStorage.setItem('language', defaultLang);
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang);
    return new Promise<void>(resolve => {
      translate.onLangChange.subscribe(() => {
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken
      }
    }),
    MatSnackBarModule,
    TopToolbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    TopToolbarComponent
  ],
  providers: [
    {provide: 'Window', useFactory: () => window},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: configurationServiceFactory, multi: true, deps: [ConfigurationService]},
    {provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService], multi: true},
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensures that CoreModule is only loaded into AppModule
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }

}
